import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import { spreadIf } from 'system';

export type RadioFieldProps<TId extends string> = Omit<RadioGroupProps, 'onChange'> & {
  label?: string | ReactNode;
  options: { value: TId; label: ReactNode }[];
  value?: string;
  disabled?: boolean;
  onChange?: (event?: React.ChangeEvent<HTMLInputElement>, value?: TId) => void;
  helperText?: ReactNode;
  error?: boolean;
};

export function RadioField<TId extends string = string>({
  label,
  sx,
  options,
  disabled,
  onChange,
  helperText,
  error,
  ...radioFieldProps
}: RadioFieldProps<TId>) {
  return (
    <FormControl fullWidth error={error} variant="filled" {...spreadIf(sx, { sx })}>
      <FormControl>
        {label && (
          <Typography fontSize="16px" color="textPrimary" marginBottom={2}>
            {label}
          </Typography>
        )}
        <RadioGroup
          color="primary"
          {...radioFieldProps}
          onChange={(evt, val) => onChange?.(evt, val as TId)}
        >
          {options.map((option) => (
            <FormControlLabel
              key={`option-${option.value}`}
              sx={{ fontSize: '13px' }}
              value={option.value}
              control={<Radio color={'primary'} size="small" disabled={disabled} />}
              label={option.label}
              checked={option.value === radioFieldProps.value}
            />
          ))}
        </RadioGroup>

        {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
      </FormControl>
    </FormControl>
  );
}
